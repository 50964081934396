import { Grid } from "@material-ui/core";
import { useStyles } from "./PostCotent.styles";
import React from 'react'
export function PostContent({ postBody }: any) {
	const classes = useStyles()
	return (
		<Grid container className={classes.root}>
			<div dangerouslySetInnerHTML={{ __html: postBody }} />
		</Grid>
	)
}