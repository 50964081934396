import React from "react";
import { PostContent } from "../../components/Blog/PostContent";
import { HeroPost, FooterPost } from "../../components";
import { Layout } from "../../layout";

function Post({ location, serverData }: any) {
	const { post } = serverData
	return (
		<Layout location={location} title={`Kredi | ${post.name}`} description={post.metaDescription} >
			<HeroPost
				created={post.created}
				title={post.name}
				author={post.authorName}
				featuredImage={post.featuredImage}
				featuredImageAltText={post.featuredImageAltText}
			/>
			<PostContent postBody={post.postBody} />
			<FooterPost />

		</Layout>
	)
}

export async function getServerData(context: any) {
	const response = await fetch(`https://api.hubapi.com/cms/v3/blogs/posts?hapikey=${process.env.GATSBY_HUBSPOT_API_KEY}&slug_eq=${context.params.id}`)
	const data = await response.json()
	return {
		props: {
			post: data?.results[0] || {}
		}
	}
}


export default Post