import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		justifyContent: "center",
		padding: '60px 290px',
		"& p, h2": {
			fontFamily: 'Circular Std',
			fontSize: "22px !important",

		},
		"& img": {
			width: '100% !important'
		},
		[theme.breakpoints.down('sm')]: {
			padding: '33px 16px',
			width: '100%',
			justifyContent: "flex-start",
			"& div": {
				width: '100%'
			},
			"& p, h2": {
				fontFamily: 'Circular Std',
				fontSize: "18px !important",
			},

		}
	}
}))